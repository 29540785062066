<template>
    <div class="w-full">
       <vs-button @click="getAllMachinesWitAbuse()"> Get </vs-button>

       {{ vmList }}
    </div>
</template>


<script>
export default {
  data() {
    return {
        total_items:'',
        last_page:'',
        current_page:'',
        vmList:[],
    };
  },
  watch: {
    currenttasksPage () {
      this.getAllMachinesWitAbuse()
    }
  },
  methods: {
    getAllMachinesWitAbuse() {
        console.log("SDFSDF");
        this.$http
        .get('/admin/cloud/vm?filter=&page=&user_id=&is_abuse=1')
        .then((res) => {
            this.vmList = res.data.params.data
        }).catch((err) => {
            this.$vs.notify({ text: err.data.message })
        })
    }
  },
  created() {
    this.getAllMachinesWitAbuse()
  },

}

</script>